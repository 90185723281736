<script setup>
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Select from 'primevue/select';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import axios from '../Axios.js';
import router from '../router/index.js';

const toast = useToast();
const loading = ref(false);

const schema = yup.object().shape({
    clientEmail: yup.string().email('Invalid email').required('This field is required'),
    accountSource: yup.string().required('This field is required'),
    writtenEstimateFee: yup.number().required('This field is required')
        .moreThan(99, 'Must be between 100 and 200')
        .lessThan(201, 'Must be between 100 and 200'),
    settledSupplementFee: yup.number().required('This field is required')
        .moreThan(14, 'Must be between 15 and 20')
        .lessThan(21, 'Must be between 15 and 20')
});

const { handleSubmit, values, defineInputBinds, errors, setFieldValue, resetForm } = useForm({
    validationSchema: schema,
    initialValues: {
        clientEmail: '',
        accountSource: '',
        writtenEstimateFee: 0,
        settledSupplementFee: 0
    }
});

// Create validated fields for each form input
const clientEmail = defineInputBinds('clientEmail');
const accountSource = defineInputBinds('accountSource');
const writtenEstimateFee = defineInputBinds('writtenEstimateFee');
const settledSupplementFee = defineInputBinds('settledSupplementFee');

const submit = handleSubmit(async (values) => {
    loading.value = true;

    const formDataToSubmit = new FormData();

    for (const [key, value] of Object.entries(values)) {
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                formDataToSubmit.append(`${key}[${index}]`, item);
            });
        } else if (typeof value === 'boolean') {
            formDataToSubmit.append(key, value ? '1' : '0');
        } else {
            formDataToSubmit.append(key, value);
        }
    }

    try {
        const response = await axios.post('/save-customization', formDataToSubmit);

        const data = await response.data;
        console.log(data);

        loading.value = false;

        toast.add({
            summary: 'Success',
            severity: 'success',
            detail: 'Successfully sent invitation',
            life: 5000
        });

        resetForm();

        const parentDomain = import.meta.env.VITE_PARENT_DOMAIN;

        await axios.post(`/send-email`, {
            emailAddresses: values.clientEmail,
            emailSubject: `Claim Connection - Client Invitation`,
            emailBody: `
You have been invited to register with Claim Connection.
Please click the link below to complete your account setup.
<br>
<a href="${parentDomain}/client-registration/?id=${data.id}">${parentDomain}/client-registration/?id=${data.id}</a>
`,
            html: true
        });
    } catch (error) {
        loading.value = false;
        console.error('Error:', error);
        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'Failed to send invitation',
            life: 5000
        });
    }
});
</script>
<style scoped>
</style>
<template>
    <div class="card flex justify-center">
        <div class="flex gap-2 flex-col mt-5 w-5/12 lg:w-8/12">
            <div class="bg-primary-200 font-medium p-8" style="margin-bottom: -10px">
                <h1 class="text-3xl">Client Registration Form Customization</h1>
                <h2 class="text-lg">For internal use only!</h2>
                <p class="mt-10">
                    Complete this form to provide specific details required for the client registration process,
                    including payment agreements and other essential information.
                </p>
                <p>
                    Once submitted, a unique link will be generated and emailed to the client.
                    This link allows the client to enter their information.
                </p>
                <p>
                    The client will be able to complete their registration and sign the pre-filled agreement
                    based on the details you provide here.
                </p>

            </div>

            <!-- Section -->
            <div class="rounded-b-2xl bg-surface-100 font-medium p-8">
                <div class="flex flex-wrap gap-5">
                    <div class="flex flex-col gap-2 w-3/12">
                        <label class="pr-5">Email</label>
                        <InputText id="cleintEmail"
                                   v-bind="clientEmail"
                                   :invalid="!!errors.clientEmail"
                                   aria-describedby="client-email-help"
                        />
                        <small id="client-email-help">The email to send the link to</small>
                        <small v-if="errors.clientEmail" class="text-red-500">
                            {{ errors.clientEmail }}
                        </small>
                    </div>
                    <div class="flex flex-col gap-2 w-2/12">
                        <div>Account Source</div>
                        <Select
                            :options="['GAF', 'Website', 'Employee Referral', 'Advertisement', 'Partner', 'Webinar', 'Other']"
                            placeholder="Select One"
                            :invalid="!!errors.accountSource"
                            :modelValue="values.accountSource"
                            @change="setFieldValue('accountSource', $event.value)"
                        />
                        <small v-if="errors.accountSource" class="text-red-500">
                            {{ errors.accountSource }}
                        </small>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label class="pr-5">Written Estimate Fee</label>
                        <InputNumber id="writtenEstimateFee"
                                     mode="currency"
                                     currency="USD"
                                     locale="en-US"
                                     fluid
                                     :modelValue="values.writtenEstimateFee"
                                     @update:modelValue="setFieldValue('writtenEstimateFee', $event)"
                        />
                        <small id="client-email-help">This accounts billable estimate fee</small>
                        <small v-if="errors.writtenEstimateFee" class="text-red-500">
                            {{ errors.writtenEstimateFee }}
                        </small>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label class="pr-5">Settled Supplement Fee</label>
                        <InputNumber id="settledSupplementFee"
                                     prefix="%"
                                     :minFractionDigits="2"
                                     :modelValue="values.settledSupplementFee"
                                     @update:modelValue="setFieldValue('settledSupplementFee', $event)"
                        />
                        <small id="client-email-help">This accounts billable supplement fee</small>
                        <small v-if="errors.settledSupplementFee" class="text-red-500">
                            {{ errors.settledSupplementFee }}
                        </small>
                    </div>
                </div>
            </div>

            <!-- SUBMIT -->
            <div class="mt-5 mb-10 flex justify-end">
                <Button @click="submit" label="Submit" />
            </div>
        </div>
    </div>

    <Toast position="top-center" />
    <BlockUI :blocked="loading" fullScreen />
    <div v-if="loading" class="fixed top-0 left-0 w-full h-full">
        <div class="flex justify-center items-center h-full">
            <ProgressSpinner />
        </div>
    </div>
</template>
